import Cookies from 'js-cookie';
import { RestaurantState } from '@/store/types';

const CART_COOKIE_EXPIRATION_DAYS = 0.5;
export const CART_COOKIE = 'cart';
export const ROTATIONAL_MENU_ORDERS_COOKIE = 'rotationalMenuOrders';

/**
 * Format a order item to the minimum required fields to be restored from a cookie
 * This is needed because the entire order item object is too large to be stored in a cookie
 *
 * @param {OrderItem} item
 * @return {MinimalOrderItem}
 */
function formatMinimalOrderItem(item: OrderItem): MinimalOrderItem {
	return {
		id: item.id,
		menu_id: item.menu_id,
		section_id: item.section_id ?? null,
		orderId: item.orderId,
		quantity: item.quantity,
		orderPrice: item.orderPrice,
		memberOrderPrice: item.memberOrderPrice,
		orderOptions: item.orderOptions?.map((option: OrderOptionGroup) => {
			// Entire memo needs to be stored in the cookie, because the data can't be retrieved from the menu
			if (option.memo) {
				return option as MinimalOrderOptionGroup;
			};
			return {
				id: option.id,
				values: option.values?.map((value: OrderOption) => {
					return {
						id: value.id,
						quantity: value.quantity,
						memberPrice: value.memberPrice,
						options: value.options?.map((subOption: OrderOptionGroup) => {
							return {
								id: subOption.id,
								values: subOption.values?.map((subValue: OrderOption) => {
									return {
										id: subValue.id,
										quantity: subValue.quantity,
										memberPrice: subValue.memberPrice
									}
								})
							}
						})
					}
				})
			}
		}) || null
	};
}

/**
 * Format order items to the minimum required fields to be restored from a cookie
 * Then store the items in the cart cookie
 * If there are no items, remove the cart cookie
 *
 * @param {OrderItem[]} items
 * @param {string} restaurantId
 * @param {number | null} eventSuiteId
 * @return {void}
 */
export function updateCartCookie(items: OrderItem[], restaurantId: string, eventSuiteId: number | null): void {
	if (!items.length) {
		Cookies.remove(CART_COOKIE);
		return;
	}
	const minimalItems: MinimalOrderItem[] = items.map((item: OrderItem) => formatMinimalOrderItem(item));
	Cookies.set(CART_COOKIE, JSON.stringify({ id: restaurantId, eventSuiteId: eventSuiteId, items: minimalItems }), { expires: CART_COOKIE_EXPIRATION_DAYS });
}

/**
 * Update the items for the given date in the rotationalMenuOrders cookie
 * If the updated order has no items, remove the order from the cookie
 * If the cookie has no orders, remove the cookie
 *
 * @param {RotationalMenuOrder} updatedOrder
 * @param {string} restaurantId
 * @return {void}
 */
export function updateRotationalMenuOrdersCookie(updatedOrder: RotationalMenuOrder, restaurantId: string): void {
	const rotationalMenuOrdersCookie = Cookies.get(ROTATIONAL_MENU_ORDERS_COOKIE);

	// Check if the cookie exists, otherwise create it
	if (rotationalMenuOrdersCookie) {
		const parsedRotationalMenuOrders = JSON.parse(rotationalMenuOrdersCookie);
		const ordersIndexToUpdate = parsedRotationalMenuOrders.orders?.findIndex((order: RotationalMenuOrder) => order.date === updatedOrder.date);

		// Check if the order exists in the cookie, otherwise add a new order
		if (typeof ordersIndexToUpdate === 'number' && ordersIndexToUpdate > -1) {
			// If the updated order has no items, remove the order from the cookie
			if (updatedOrder.items.length === 0) {
				parsedRotationalMenuOrders.orders.splice(ordersIndexToUpdate, 1);
			}
			else {
				parsedRotationalMenuOrders.orders[ordersIndexToUpdate] = {
					...updatedOrder,
					items: updatedOrder.items.map((item: OrderItem) => formatMinimalOrderItem(item))
				};
			}
		}
		else {
			const minifiedOrder = {
				...updatedOrder,
				items: updatedOrder.items.map((item: OrderItem) => formatMinimalOrderItem(item))
			}
			parsedRotationalMenuOrders.orders.push(minifiedOrder);
		}

		// If there are no orders after updating the order, remove the cookie
		if (parsedRotationalMenuOrders.orders.length === 0) {
			Cookies.remove(ROTATIONAL_MENU_ORDERS_COOKIE);
		}
		else {
			Cookies.set(ROTATIONAL_MENU_ORDERS_COOKIE, JSON.stringify(parsedRotationalMenuOrders), { expires: CART_COOKIE_EXPIRATION_DAYS });
		}
	}
	else {
		const minifiedOrder = {
			...updatedOrder,
			items: updatedOrder.items.map((item: OrderItem) => formatMinimalOrderItem(item))
		}
		Cookies.set(ROTATIONAL_MENU_ORDERS_COOKIE, JSON.stringify({ id: restaurantId, orders: [minifiedOrder] }), { expires: CART_COOKIE_EXPIRATION_DAYS });
	}
}

/**
 * Get the full OrderItem from the menus based on the minimal order items stored in the cookie
 *
 * @param {MinimalOrderItem[]} orderItems
 * @param {Menu[]} menus
 * @return {OrderItem[]}
 */
export function getItemsFromCookie(orderItems: MinimalOrderItem[], restaurantState: RestaurantState): OrderItem[] {
	const { filteredMenus, restaurant } = restaurantState;
	const cartItems: OrderItem[] = [];
	for (const item of orderItems) {
		let menuItem: MenuItem | undefined;
		const menu = filteredMenus.find((menu) => menu.id === item.menu_id);
		if (!menu) {
			continue;
		}
		if (item.section_id) {
			const section = menu.sections.find((section) => section.id === item.section_id);
			if (!section) {
				continue;
			}

			menuItem = section.items.find((menuItem) => menuItem.id === item.id);
		}
		else {
			menuItem = menu.items.find((menuItem) => menuItem.id === item.id);
		}
		if (!menuItem || menuItem.sold_out) {
			continue;
		}

		const updatedCartItemCount = cartItems.reduce((acc, item) => acc + item.quantity, 0) + item.quantity;
		if (updatedCartItemCount > (restaurant.maxItemsPerCart || Infinity)) {
			// If max items is exceed by adding a single item, the cart is full so stop trying to add more items
			if (item.quantity === 1) {
				break;
			}

			item.quantity = item.quantity - (updatedCartItemCount - restaurant.maxItemsPerCart!);
		}

		cartItems.push({
			...menuItem,
			menu_id: item.menu_id,
			quantity: item.quantity,
			orderId: item.orderId,
			orderOptions: item.orderOptions?.length ? getOptionsFromCookie(item.orderOptions,  menuItem.options || []) : [],
			orderPrice: item.orderPrice,
			memberOrderPrice: item.memberOrderPrice
		});
	}
	return cartItems;
}

/**
 * Get the full OrderOptionGroup from the menus based on the minimal order option groups stored in the cookie
 * This is a recursive function that will get the full option group and all sub-options
 *
 * @param {MinimalOrderOptionGroup[]} orderOptionGroups - Option groups from the cookie
 * @param {OptionGroup[]} menuItemOptionGroups - Option groups from the menu
 * @return {OrderOptionGroup[]}
 */
function getOptionsFromCookie(orderOptionGroups: MinimalOrderOptionGroup[], menuItemOptionGroups: OptionGroup[]): OrderOptionGroup[] {
	const options: OrderOptionGroup[] = [];
	for (const orderOptionGroup of orderOptionGroups) {
		// Check if the orderOptionGroup has values before getting find the corresponding menuItemOptionGroup
		if (orderOptionGroup.id && orderOptionGroup.values?.length) {
			const menuItemOptionGroup = menuItemOptionGroups.find((option) => option.id === orderOptionGroup.id);
			if (!menuItemOptionGroup) {
				continue;
			}
			const values: OrderOption[] = [];
			for (const orderOption of orderOptionGroup.values) {
				const menuItemOption = menuItemOptionGroup.values?.find((option) => option.id === orderOption.id);
				if (!menuItemOption) {
					continue;
				}
				// Format the orderOption from the cookie to match what how the options are structured when an item is added to the cart
				// The order of properties is important since when we add an item to the cart we compare options by their stringified values
				// So if the order of properties is different, the comparison will fail
				const option: OrderOption = {
					id: menuItemOption.id,
					name: menuItemOption.name,
					localization: menuItemOption.localization,
					calories: menuItemOption.calories,
					product_id: menuItemOption.product_id,
					sku: menuItemOption.sku,
					category: menuItemOption.category,
					tax_rate: menuItemOption.tax_rate,
					price: menuItemOption.price,
					memberPrice: orderOption.memberPrice,
					promos: menuItemOption.promos,
					roll_up: menuItemOption.roll_up,
					order: menuItemOption.order,
					quantity: orderOption.quantity,
					default_selection: menuItemOption.default_selection,
				}
				// Add sub-options if there were any selected
				if (orderOption.options?.length) {
					option.options = orderOption.options?.length && menuItemOption.options?.length ? getOptionsFromCookie(orderOption.options, menuItemOption.options) : [];
				}
				values.push(option);
			}

			// Add the orderOptionGroup to the options array
			options.push({
				id: menuItemOptionGroup.id,
				name: menuItemOptionGroup.name,
				localization: menuItemOptionGroup.localization,
				optionGroupSku: menuItemOptionGroup.sku,
				optionGroupType: menuItemOptionGroup.type,
				requiredOption: menuItemOptionGroup.required,
				multipleOption: menuItemOptionGroup.allow_multiple_selection,
				order: menuItemOptionGroup.order,
				memo: false,
				values
			});
		}
		// If option is a memo, we add it to the options array
		// Memos are stored in full in the cookie
		else if (orderOptionGroup.memo) {
			options.push(orderOptionGroup as OrderOptionGroup);
		}
	}
	return options;
}

/**
 * Get the rotationalMenuOrders from the cookie
 * Items are stored with as little data as possible to save space, so we need to get the full item from the menus
 *
 * @param {RotationalMenuOrder[]} rotationalMenuOrders
 * @param {Menu[]} menus
 * @return {RotationalMenuOrder[]}
 */
export function getRotationalMenuOrdersFromCookie(rotationalMenuOrders: RotationalMenuOrder[], restaurantState: RestaurantState): RotationalMenuOrder[] {
	const orders: RotationalMenuOrder[] = [];
	for (const order of rotationalMenuOrders) {
		orders.push({
			...order,
			items: getItemsFromCookie(order.items, restaurantState)
		});
	}
	return orders;
}