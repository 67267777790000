import { ActionTree } from 'vuex';
import { RestaurantState, RootState } from '../types';
import { state } from './restaurant';
import { state as authState } from '../auth/auth';
import i18n from '@/i18n';

export const actions: ActionTree<RestaurantState, RootState> = {
	setRestaurant({ commit }, restaurant: Restaurant): void {
		commit('SET_RESTAURANT', restaurant);
	},

	/**
	 * Set the initial menu groups
	 *
	 * @param {MenuGroup[]} initialMenus
	 * @return {Promise}
	 */
	setInitialMenuGroups({ commit }, initialMenuGroups: MenuGroup[]): void {
		commit('SET_INITIAL_MENU_GROUPS', initialMenuGroups);
	},

	/**
	 * Set the initial menus (not filtered by order type or any other conditions)
	 *
	 * @param {Menu[]} initialMenus
	 * @return {Promise}
	 */
	setInitialMenus({ commit }, initialMenus: Menu[]): void {
		commit('SET_INITIAL_MENUS', initialMenus);
	},

	/**
	 * Set the final filtered menus by all types (member menus, query params, etc)
	 *
	 * @param {Menu[]} filteredMenus
	 * @return {Promise}
	 */
	setFilteredMenus({ commit }, filteredMenus: Menu[]): void {
		commit('SET_FILTERED_MENUS', filteredMenus);
	},

	/**
	 * Set the member prices of items/options
	 *
	 * @param {Menu[]} menus
	 * @return {Promise}
	 */
	async setMemberPrices({ commit, dispatch }, menus: Menu[]): Promise<Menu[]> {
		return await new Promise((resolve) => {
			commit('SET_MEMBER_PRICES', menus);

			// Calculate cart items member prices
			dispatch('cart/calculateCartItemsMemberPrices', {}, { root: true });
			resolve(state.memberMenus);
		});
	},

	/**
	 * Get the payment config from salata of the restaurant
	 *
	 * @return {Promise}
	 */
	async getSalataRestaurantPaymentConfig({ commit }): Promise<any> {
		const payload = {
			app8RestaurantId: state.restaurant.app8_restaurant
		};

		return await new Promise((resolve, reject) => {
			authState.axiosInstance
				.post(`${process.env.VUE_APP_API_PREFIX}/payment/get-payment-config`, payload)
				.then(response => {
					commit('SET_PAYMENT_CONFIG', response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	/**
	 * Fetch rotational menus from the db for the selected date range
	 * Rotational menus are created in the menu manager by adding items to a menu with a specific date, which is separate from the menus in the data.json file
	 * This data isn't stored in the data.json file because it would be too much data to have to fetch in one request (approx. 200 daily menus for a school year)
	 * It also wouldn't be feasible to rebuild the data.json file and refetch it every the user changes the date range, so we fetch the rotational menus separately
	 *
	 * @return {Promise<void>}
	 */
	async fetchRotationalMenus({ commit }): Promise<void> {
		try {
			commit('SET_ROTATIONAL_MENUS_FETCHING', true);
			if (!state.rotationalMenuDateRange.start || !state.rotationalMenuDateRange.end) {
				throw i18n.t('menu.error_no_date_range');
			}
			const { data } = await authState.axiosInstance.get(`${process.env.VUE_APP_API_PREFIX}/rotational-menus?start_date=${state.rotationalMenuDateRange.start}&end_date=${state.rotationalMenuDateRange.end}&ordering_menus=true`);
			commit('SET_ROTATIONAL_MENUS', data);
		}
		catch (error) {
			throw error;
		}
		finally {
			setTimeout(() => {
				commit('SET_ROTATIONAL_MENUS_FETCHING', false);
			}, 300);
		}
	},

	setDateRange({ commit, dispatch }, dateRange: DateRange): void {
		commit('SET_ROTATIONAL_MENU_DATE_RANGE', dateRange);
		dispatch('fetchRotationalMenus');
	},

	resetRestaurantState({ commit }): void {
		commit('RESET_RESTAURANT_STATE');
	}
};