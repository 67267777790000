import { GetterTree } from 'vuex';
import { DateTime } from 'luxon';
import { RestaurantState, RootState } from '../types';
import { state as cartState } from '../cart/cart';
import { generateAvailableFilters } from '@/utils/helpers';

export const getters: GetterTree<RestaurantState, RootState> = {
	getRestaurant(state): Restaurant {
		return state.restaurant;
	},

	getRestaurantName(state): string {
		return state.restaurant && state.restaurant.name;
	},

	getRestaurantVoucher(state): object | undefined {
		return state.restaurant && state.restaurant.voucher;
	},

	getRestaurantExtraPaymentMethods(state): RestaurantExtraPaymentMethods[] | null | undefined {
		return state.restaurant && state.restaurant.extraPaymentMethods;
	},

	getRestaurantDiscount(state): boolean {
		return state.restaurant.hasDiscounts;
	},

	getRestaurantTipConfig(state): object | undefined {
		return state.restaurant && state.restaurant.tipConfig;
	},

	getMaxItemsPerCart(state): number | null {
		return state.restaurant && state.restaurant.maxItemsPerCart;
	},

	getSupportEmail(state): string | null {
		return state.restaurant && state.restaurant.supportEmail;
	},

	getRestaurantDeliveryInfo(state): DefaultDelivery | StadiumDelivery | CustomDelivery {
		return state.restaurant && state.restaurant.delivery!;
	},

	getRestaurantMembershipCustomization(state): MembershipCustomization {
		return state.restaurant && state.restaurant.membershipCustomization!;
	},

	getRestaurantMembershipCustomizationLoadingMessage(state): string | undefined {
		return state.restaurant && state.restaurant.membershipCustomization && state.restaurant.membershipCustomization.loading_message;
	},

	getRestaurantPaymentProcessor(state): string {
		return state.restaurant && state.restaurant.payment_processor;
	},

	getRestaurantPaymentConfig(state): PaymentConfig | null {
		return state.paymentConfig;
	},

	getInitialMenuGroups(state): MenuGroup[] {
		return state.initialMenuGroups;
	},

	getInitialMenus(state): Menu[] {
		return state.initialMenus;
	},

	getFilteredMenus(state): Menu[] {
		return state.filteredMenus;
	},

	getMemberMenus(state): Menu[] {
		return state.memberMenus;
	},

	getRotationalMenus(state): RotationalMenu[] {
		return state.rotationalMenus;
	},

	getTenantId(state): string {
		return state.restaurant && state.restaurant.id;
	},

	getGenericTableLocations(state): GenericTableLocations | null {
		return state.restaurant && state.restaurant.genericTableLocations;
	},

	getConcourseUrl(state): string | null {
		return state.restaurant && state.restaurant.concourse;
	},

	getAvailableDiets(state): string[] {
		let output: string[] = [];
		state.itemList.forEach(item => {
			output = generateAvailableFilters(output, 'diets', item);
		});
		return output;
	},

	getAvailableAllergens(state): string[] {
		let output: string[] = [];
		state.itemList.forEach(item => {
			output = generateAvailableFilters(output, 'allergens', item);
		});
		return output;
	},

	hideItemsSpecialInstructions(state): boolean {
		return state.restaurant && state.restaurant.hide_items_special_instructions;
	},

	hideTakeoutSpecialInstructions(state): boolean {
		return state.restaurant && state.restaurant.hide_takeout_special_instructions;
	},

	isLoyaltyProgramOn(state): string {
		return state.restaurant && state.restaurant.loyalty_program;
	},

	isMembershipProgramOn(state): string {
		return state.restaurant && state.restaurant.membership_program;
	},

	isPosIntegrated(state): boolean {
		return state.restaurant && state.restaurant.pos_integrated;
	},

	isOpenTab(state): boolean {
		return state.restaurant && state.restaurant.allow_open_tab;
	},

	noTakeoutTimeslot(state): boolean {
		return state.restaurant.noTakeoutTimeslot;
	},

	isAvsRequired(state): boolean {
		return state.restaurant && state.restaurant.avs;
	},

	isMarketplaceHub(state): boolean {
		return state.restaurant && state.restaurant.marketplaceHub;
	},

	isMultipleMids(state): boolean {
		return state.restaurant && state.restaurant.multipleMids;
	},

	getCustomQuestions(state): CustomQuestion[] {
		return state.restaurant.customQuestions || [];
	},

	getHideGuestInfo(state): HideGuestInfo | undefined {
		return state.restaurant?.hideGuestInfo;
	},

	getRestaurantGroup(state): string | undefined {
		return state.restaurant?.group;
	},

	getZone(state): string | undefined {
		return state.restaurant?.menu_configuration?.zone;
	},

	isOrderItemAgainEnabled(state): boolean | undefined {
		return state.restaurant?.isOrderAgain;
	},

	showSplitTaxes(state): boolean {
		return state.restaurant?.showSplitTaxes;
	},

	orderingRequiresLogin(state): boolean {
		return (state.restaurant.k12 && state.restaurant.k12RequiresLogin) || (cartState.config.genericCatering && state.restaurant.genericCateringRequiresLogin);
	},

	isK12Location(state): boolean {
		return state.restaurant?.k12;
	},

	getDateRange(state): DateRange {
		return state.rotationalMenuDateRange;
	},

	getOrderCutoffDate(state): DateTime | null {
		return state.restaurant?.orderCutoffDate;
	},

	hasWeekendOrdering(state): boolean {
		return state.restaurant?.weekendOrdering || false;
	},

	getMaxAdvancedOrderDays(state): number | null {
		return state.restaurant?.maxAdvancedOrderDays;
	},

	getRotationalMenusFetching(state): boolean {
		return state.rotationalMenusFetching;
	},

	genericCateringHideCreditCard(state): boolean {
		return state.restaurant?.genericCateringHideCreditCard;
	}
};