import i18n from '@/i18n';
import Vue from 'vue';
import Cookies from 'js-cookie';
import store from '../';
import { DateTime } from 'luxon';
import { MutationTree } from 'vuex';
import { CartState } from '../types';
import { state as restaurantState } from '../restaurant/restaurant';
import { state as authState } from '../auth/auth';
import { state as suitesState } from '../suites/suites';
import { formatTemporarySelectedOrderItem, formatOrderOptionsToCompare } from '../../utils/format';
import { sortOrderOptions } from '@/utils/sort';
import { getElementBySkuOrId } from '@/utils/helpers';
import { updateCartCookie, updateRotationalMenuOrdersCookie, getItemsFromCookie, getRotationalMenuOrdersFromCookie, CART_COOKIE, ROTATIONAL_MENU_ORDERS_COOKIE } from '@/utils/restore-cart';

/**
 * Set the frontend order price (this price is just for visual purposes
 * and will not be used for actual calculations, all that happens in the
 * backend)
 *
 * @param {OrderItem} item
 * @return {void}
 */
function setLocalOrderPrice(item: OrderItem): OrderItem {
	const { addonPrice, addonMemberPriceFound, addonMemberPrice } = setAddonsLocalPrice(item, 'orderOptions');

	// Set member order price if the item has a member price, if there was a member price in any of the options selected
	// OR if there was a memberOrderPrice beforehand.
	if(item.memberOrderPrice || item.memberPrice || addonMemberPriceFound) {
		item.memberOrderPrice = +(((item.memberPrice !== undefined ? +item.memberPrice : +item.price) + addonMemberPrice) * item.quantity).toFixed(2);
	}
	item.orderPrice = +((+item.price + addonPrice) * item.quantity).toFixed(2);

	return item;
}

/**
 * Set the frontend order price (this price is just for visual purposes
 * and will not be used for actual calculations, all that happens in the
 * backend)
 *
 * @param {OrderItem | OrderOptionGroup} item
 * @param {string} arrayName
 * @return {void}
 */
function setAddonsLocalPrice(item: any, arrayName: string): AddonsLocalOrderPriceInfo {
	let addonPrice: number = 0;
	let addonMemberPriceFound: boolean = false;
	let addonMemberPrice: number = 0;
	if(item[arrayName] && item[arrayName].length) {
		item[arrayName].forEach((orderOption: OrderOptionGroup) => {
			orderOption.values!.forEach((value: OrderOption) => {
				const quantity = value.quantity || 0;
				// Don't add to addon price if it's a pricing option since it overrides the item's price instead
				if(orderOption.optionGroupType !== 'pricing') {
					// Add to total addon member price if the addon has one
					// If there is none we add it to the total anyways, since
					// some options may have a normal price where the other has
					// a member, we need the total.
					if(value.memberPrice !== undefined) {
						addonMemberPriceFound = true;
						addonMemberPrice += +value.memberPrice * quantity;
					}
					else {
						addonMemberPrice += +value.price! * quantity;
					}

					addonPrice += +value.price! * quantity;
				}

				// Get sub options price if any and add them to our current amounts
				const subOptionAddonPrices = setAddonsLocalPrice(value, 'options');
				addonPrice += subOptionAddonPrices.addonPrice * quantity;
				addonMemberPrice += subOptionAddonPrices.addonMemberPrice * quantity;
				if(!addonMemberPriceFound) {
					addonMemberPriceFound = subOptionAddonPrices.addonMemberPriceFound;
				}
			});
		});
	}


	return { addonPrice, addonMemberPriceFound, addonMemberPrice };
}

/**
 * Finds all the default options and sets them as selected for the temporary item
 *
 * @param {OptionGroup[]} optionGroups
 * @return {OptionGroup[]}
 */
function setDefaultOrderOptions(optionGroups?: OptionGroup[]): OptionGroup[] {
	if (!optionGroups) {
		return [];
	}
	const optionGroupsCopy = structuredClone(optionGroups);

	return optionGroupsCopy.reduce((acc: OptionGroup[], optionGroup: OptionGroup) => {
		if (optionGroup.type === 'pricing') {
			return acc;
		}
		const defaultOptions = optionGroup.values?.filter((option: Option) => option.default_selection);

		if (defaultOptions && defaultOptions.length) {
			// Recursively set default options for sub options
			defaultOptions.forEach((option: Option) => {
				if (option.options && option.options.length) {
					option.options = setDefaultOrderOptions(option.options);
				}
			});
			optionGroup.values = defaultOptions;
			acc.push(optionGroup);
		}
		return acc;
	}, []);
}

function setLastOrderOptions(tempItem: MenuItem): OptionGroup[] {
	if (!tempItem.options) {
		return [];
	}
	const optionGroupsCopy = structuredClone(tempItem.options);

	return optionGroupsCopy.reduce((acc: OptionGroup[], optionGroup: OptionGroup) => {
		const lastOrderedOptions: Option[] | undefined = optionGroup.values?.filter((option: Option) => tempItem.lastOrderedOptions?.map(({ id, sku }: LastOrderedItem) => ({ id, sku })).some(({ id, sku }) => getElementBySkuOrId(option, id, sku)));
		if (lastOrderedOptions?.length) {
			optionGroup.values = lastOrderedOptions.map((option: Option) => {
				const lastOrderedOption = tempItem.lastOrderedOptions?.find(({ id, sku }: LastOrderedItem) => getElementBySkuOrId(option, id, sku));
				// Update the quantity of the option with the quantity of the matching lastOrderedOption
				if (lastOrderedOption?.quantity) {
					option.quantity = lastOrderedOption.quantity;
				}
				return option;
			});

			acc.push(optionGroup);
		}
		return acc;
	}, [])
}

/**
 * Add temporary price and the special_instructions field to the object.
 *
 * @param {OrderItem} item
 * @param {any} extraData
 * @return {OrderItem}
 */
function addTempFields(item: OrderItem, extraData: any): OrderItem {
	if (extraData.special_instructions) {
		item.orderOptions!.push(
			{
				name: i18n.t('vuex_store.cart.special_instructions_name'),
				memo: true,
				values: [
					{
						name: extraData.special_instructions,
						price: '0',
						quantity: 1,
						roll_up: true
					}
				]
			}
		);
	}

	return item;
}

/**
 * Update order fields: quantity, price and id
 *
 * @param {OrderItem} tempItem
 * @param {OrderItem} item
 * @return {OrderItem}
 */
function updateOrderFields(tempItem: OrderItem, item: OrderItem): OrderItem {
	tempItem.quantity += item.quantity;
	item.orderPrice = item.orderPrice! + tempItem.orderPrice!;
	tempItem.orderPrice = item.orderPrice;
	tempItem.orderId = item.orderId;
	// Set the prices with quantity update
	tempItem = { ...setLocalOrderPrice(tempItem) };
	return tempItem;
}

export const mutations: MutationTree<CartState> = {
	/**
	 * Add the temporary item to the OrderItem list.
	 * If the location is a K12 location, we also add the item to the rotationalMenuOrders list for the given date.
	 *
	 * @param {CartState} state
	 * @param {any} extraData
	 * @return {void}
	 */
	ADD_TO_CART(state: CartState, extraData: any): void {
		if(state.temporarySelectedItem) {
			state.temporarySelectedItem = addTempFields(state.temporarySelectedItem, extraData);
			state.temporarySelectedItem.orderOptions! = sortOrderOptions(state.temporarySelectedItem.orderOptions!);
			const { itemsInCart } = extraData;
			const isK12Location = restaurantState.restaurant.k12;

			if(itemsInCart.length) {
				let existInArray = false;
				let orderOptionsSame = false;

				/**
				 * We loop through the items to check if the item already exists in the array,
				 * from there we check if the order options are the same, if they are we simply increase
				 * the quantity.
				 */
				for (let index = 0; index < itemsInCart.length; index++) {
					if(itemsInCart[index].id === state.temporarySelectedItem.id) {
						existInArray = true;
						const tempItemOrderOptions = formatOrderOptionsToCompare(state.temporarySelectedItem.orderOptions);
						const itemToCompareOrderOptions = formatOrderOptionsToCompare(itemsInCart[index].orderOptions);
						if(JSON.stringify(tempItemOrderOptions) === JSON.stringify(itemToCompareOrderOptions)) {
							orderOptionsSame = true;
							state.temporarySelectedItem = updateOrderFields(state.temporarySelectedItem, itemsInCart[index]);
							itemsInCart.splice(index, 1, state.temporarySelectedItem);
							updateCartCookie(itemsInCart, restaurantState.restaurant.id, suitesState.eventSuiteId);
							break;
						}
					}
				}

				/**
				 * If the item exists in the array, we check if the options were the same
				 * so we don't add the item twice
				 */
				if(existInArray) {
					if(!orderOptionsSame) {
						state.temporarySelectedItem.orderId = state.orderId++;
						itemsInCart.push(state.temporarySelectedItem);
						updateCartCookie(itemsInCart, restaurantState.restaurant.id, suitesState.eventSuiteId);
					}
				}

				/**
				 * If the item does not exists in the array, we simply add it
				 */
				else {
					state.temporarySelectedItem.orderId = state.orderId++;
					itemsInCart.push(state.temporarySelectedItem);
					updateCartCookie(itemsInCart, restaurantState.restaurant.id, suitesState.eventSuiteId);
				}

				// If the location is a K12 location and an order already exists in the cart for the given date, we update the order items
				if (isK12Location && extraData.orderDate) {
					state.rotationalMenuOrders.findIndex((order: RotationalMenuOrder) =>  {
						if(order.date === extraData.orderDate) {
							order.items = itemsInCart;
							updateRotationalMenuOrdersCookie(order, restaurantState.restaurant.id);
						}
					});

				}
			}

			/**
			 * Array empty so we simply add to it
			 */
			else {
				state.temporarySelectedItem.orderId = state.orderId++;
				itemsInCart.push(state.temporarySelectedItem);
				updateCartCookie(itemsInCart, restaurantState.restaurant.id, suitesState.eventSuiteId);

				// Add the item to the rotationalMenuOrders list if the location is a K12 location
				if (isK12Location && extraData.orderDate) {
					const updatedOrder = {
						items: itemsInCart,
						orderId: state.orderId,
						date: extraData.orderDate
					}
					state.rotationalMenuOrders.push(updatedOrder);
					updateRotationalMenuOrdersCookie(updatedOrder, restaurantState.restaurant.id);
				}
			}
		}
	},

	/**
	 * Increase quantity of the temporary item
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	ADD_TEMPORARY_ITEM(state: CartState): void {
		if (state.temporarySelectedItem) {
			state.temporarySelectedItem.quantity++;
			// Break object reference for reactivity purposes (Vue3 fixes this)
			state.temporarySelectedItem = { ...setLocalOrderPrice(state.temporarySelectedItem) };
		}
	},

	/**
	 * Decrease the quantity of the temporary item
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	REMOVE_TEMPORARY_ITEM(state: CartState): void {
		if (state.temporarySelectedItem) {
			if(state.temporarySelectedItem.quantity >= 2) {
				state.temporarySelectedItem.quantity--;
				state.temporarySelectedItem = { ...setLocalOrderPrice(state.temporarySelectedItem) };
			}
		}
	},

	/**
	 * Set temporary item when user is on the expanded view so we can
	 * manipulate the data if the item is added later on.
	 *
	 * @param {CartState} state
	 * @param {{ tempItem: MenuItem, quantity?: number }} tempItemPayload
	 * @return {void}
	 */
	SET_TEMPORARY_ITEM(state: CartState, { tempItem, quantity }: { tempItem: MenuItem, quantity?: number }): void {
		const itemCopy = formatTemporarySelectedOrderItem(tempItem);
		itemCopy.quantity = quantity || 1;
		itemCopy.orderOptions = tempItem.lastOrderedOptions?.length ? setLastOrderOptions(tempItem) : setDefaultOrderOptions(tempItem.options);
		state.temporarySelectedItem = itemCopy;
		state.temporarySelectedItem = { ...setLocalOrderPrice(state.temporarySelectedItem) };
	},

	/**
	 * Update temporary item price
	 *
	 * @param {CartState} state
	 * @param {string} price
	 * @return {void}
	 */
	UPDATE_TEMPORARY_ITEM_PRICE(state: CartState, prices: Prices): void {
		Vue.set(state.temporarySelectedItem, 'price', prices.price);

		// We wanna update the member price of the item if there is already one.
		// If the pricing option had no memberPrice, we stil update the memberPrice with
		// the default price of the pricing option, to keep everything in sync.
		if(state.temporarySelectedItem.memberPrice || prices.memberPrice) {
			if(prices.memberPrice) {
				Vue.set(state.temporarySelectedItem, 'memberPrice', prices.memberPrice);
			}
			else {
				Vue.set(state.temporarySelectedItem, 'memberPrice', prices.price);
			}
		}
	},

	/**
	 * Remove unique OrderItem from list
	 *
	 * @param {CartState} state
	 * @param {OrderItem} menuItem
	 * @return {void}
	 */
	REMOVE_FULL_ITEM_FROM_CART(state: CartState, payload: { menuItem: OrderItem, orderDate?: string }): void {
		const { menuItem, orderDate } = payload;

		// Order date will only be present if ordering from a rotational menu
		if (orderDate) {
			const order = state.rotationalMenuOrders.find((order) => order.date === orderDate);

			if (order) {
				const index = order.items.findIndex(item => item.orderId === menuItem.orderId);

				if(index > -1) {
					order.items.splice(index, 1);

					if (!order.items.length) {
						state.rotationalMenuOrders = state.rotationalMenuOrders.filter((order) => order.date !== orderDate);
					}
				}

				updateRotationalMenuOrdersCookie({ orderId: order.orderId, date: orderDate, items: order?.items || [] }, restaurantState.restaurant.id);
			}
		}
		else {
			const index = state.items.findIndex(item => item.orderId === menuItem.orderId);

			if(index > -1) {
				state.items.splice(index, 1);
				updateCartCookie(state.items, restaurantState.restaurant.id, suitesState.eventSuiteId);
			}
		}
	},

	/**
	 * Set items and costs calculated from the database
	 *
	 * @param {CartState} state
	 * @param {OrderItem} tempItem
	 * @return {void}
	 */
	SET_COSTS(state: CartState, payload: any): void {
		Vue.set(state, 'items', payload.orderItems);
		Vue.set(state, 'costs', payload.costs);
		Vue.set(state, 'queue', payload.queue);
	},

	/**
	 * Set tax rate of restaurant
	 * TODO: SET ALL RESTAURANT INFO UNDER A RESTAURANT OBJECT IN STATES
	 *
	 * @param {CartState} state
	 * @param {Restaurant} restaurant
	 * @return {void}
	 */
	SET_RESTAURANT_CHARGES(state: CartState, restaurant: Restaurant): void {
		if(restaurant.serviceCharge) {
			// Get the value without the symbol ($, %)
			Vue.set(state.costs.serviceCharge, 'type', restaurant.serviceCharge.slice(restaurant.serviceCharge.length - 1) === '$' ? 'dollar' : 'percentage');

			// Set the percentage value for display purposes
			if(state.costs.serviceCharge.type === 'percentage') {
				Vue.set(state.costs.serviceCharge, 'percentageValue', +restaurant.serviceCharge.slice(0, -1));
			}
			Vue.set(state.costs.serviceCharge, 'label', restaurant.serviceChargeLabel);
		}
		if(restaurant.deliveryCharge) {
			// Get the value without the symbol ($, %)
			Vue.set(state.costs.deliveryCharge, 'type', restaurant.deliveryCharge.slice(restaurant.deliveryCharge.length - 1) === '$' ? 'dollar' : 'percentage');

			// Set the percentage value for display purposes
			if(state.costs.deliveryCharge.type === 'percentage') {
				Vue.set(state.costs.deliveryCharge, 'percentageValue', +restaurant.deliveryCharge.slice(0, -1));
			}
		}
	},

	/**
	 * Set the value from the browsers cookies to our store.
	 * This includes contact info, membership flag, and legal check.
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	 GET_COOKIES(state: CartState): void {
		const name = Cookies.get('name');
		const email = Cookies.get('email');
		const phone = Cookies.get('phone');

		if(name || email || phone) {
			Vue.set(state.checkout, 'contact', {
				full_name: name ? name : '',
				email: email ? email : '',
				phone_number: phone ? phone : ''
			});
		}

		const legal = Cookies.get('legal') === 'true';
		if(legal) {
			Vue.set(state, 'legal', legal);
		}
	},

	/**
	 * Set cart items from the cookies
	 *
	 * @param {CartState} state
	 * @param {boolean} orderingRequiresLogin
	 * @return {void}
	 */
	SET_CART_FROM_COOKIES(state: CartState, orderingRequiresLogin: boolean): void {
		const cart = Cookies.get(CART_COOKIE) ? JSON.parse(Cookies.get(CART_COOKIE)!) : null;
		const rotationalMenuOrders = Cookies.get(ROTATIONAL_MENU_ORDERS_COOKIE) ? JSON.parse(Cookies.get(ROTATIONAL_MENU_ORDERS_COOKIE)!) : null;

		// Check if the cart/rotationalMenuOrders cookie is from the same location as the current restaurant
		const atSameLocation = (cart?.id || rotationalMenuOrders?.id) === restaurantState.restaurant.id;

		// If the user is not required to login to order, we can load the cart
		// If the user is logged in, we can always load the cart (assuming other conditions pass)
		const authenticatedOrLoginNotRequired = (!orderingRequiresLogin || authState.authenticated);

		// Suites edge cases
		// If the user is pre-ordering, we only want to load the cart if the user has not already pre-ordered (already handled by pre-order confirmation modal)
		// If pre-order is not placed or event day ordering, only load the cart if the selected event suite id matches the cart cookie's event suite id
		// If the user is not placing a suites order, remove the cookie if there is an event suite id in the cart cookie
		const alreadyPreOrdered = !!(suitesState.preOrdering && suitesState.userEventSuite?.orders.length);
		const validSuitesCart = (!alreadyPreOrdered || suitesState.eventDayOrdering) && suitesState.eventSuiteId === cart?.eventSuiteId;
		const notSuitesOrder = !!(!(suitesState.preOrdering || suitesState.eventDayOrdering) && !cart?.eventSuiteId);

		if (atSameLocation && authenticatedOrLoginNotRequired && (validSuitesCart || notSuitesOrder)) {
			if (cart?.items && !restaurantState.restaurant.k12) {
				Vue.set(state, 'items', getItemsFromCookie(cart.items, restaurantState));
			}

			if (rotationalMenuOrders?.orders && restaurantState.restaurant.k12) {
				Vue.set(state, 'rotationalMenuOrders', getRotationalMenuOrdersFromCookie(rotationalMenuOrders.orders, restaurantState));
			}
		}
		else {
			state.items = [];
			Cookies.remove(CART_COOKIE);
			Cookies.remove(ROTATIONAL_MENU_ORDERS_COOKIE);
		}
	},

	/**
	 * Set the cookies in the browser with the contact information
	 * filled out by the user
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	 SET_COOKIES(state: CartState): void {
		if(state.checkout.contact) {
			Cookies.set('name', state.checkout.contact.full_name);
			Cookies.set('email', state.checkout.contact.email);
			Cookies.set('phone', state.checkout.contact.phone_number);
		}

		if(state.checkout.memberInfo && state.checkout.memberInfo.id) {
			Cookies.set(`${restaurantState.restaurant.slug}-member`, state.checkout.memberInfo.identifier);
		}
	},

	/**
	 * Set the loyalty program payload
	 *
	 * @param {CartState} state
	 * @param {CheckoutLoyaltyProgramInfo} payload
	 * @return {void}
	 */
	SET_LOYALTY_PROGRAM(state: CartState, payload: CheckoutLoyaltyProgramInfo): void {
		Vue.set(state.checkout, 'loyaltyProgram', payload);
	},

	/**
	 * Set loyalty program user status
	 *
	 * @param {CartState} state
	 * @param {string} value
	 * @return {void}
	 */
	SET_USER_LOYALTY_PROGRAM_STATUS(state: CartState, value: string): void {
		if(state.checkout && state.checkout.loyaltyProgram) {
			Vue.set(state.checkout.loyaltyProgram, 'userStatus', value);
		}
	},

	/**
	 * Update the order options when selecting an addon
	 *
	 * @param {CartState} state
	 * @param {any} options
	 * @return {void}
	 */
	UPDATE_ORDER_OPTIONS(state: CartState, options: any): void {
		if(state.temporarySelectedItem) {
			// Here we need to compromise with vue 2 reactivity issues. Since Vue
			// does not want to run comparisons across the whole keys and values for
			// performance reason, we have to change the object completely to allow
			// Vue to determine that the object has changed (nested objects problem)
			// (VUE 3 FIXES THIS)
			Vue.set(state.temporarySelectedItem, 'orderOptions', null);
			Vue.set(state.temporarySelectedItem, 'orderOptions', options);
			state.temporarySelectedItem = { ...setLocalOrderPrice(state.temporarySelectedItem) };
		}
	},

	/**
	 * Update tip of the complete order
	 *
	 * @param {CartState} state
	 * @param {any} quantityObject
	 * @return {void}
	 */
	UPDATE_TIP(state: CartState, tipObject: any): void {
		if(tipObject) {
			// Ensure positive values (We do this in multiple places, including the backend obviously, better be safe than sorry)
			if(tipObject.tip < 0) {
				tipObject.tip = Math.abs(tipObject.tip);
			}

			if(tipObject.type === 'percentage') {
				Vue.set(state.costs.tip, 'percentageValue', tipObject.tip);
				Vue.set(state.costs.tip, 'amount', 0);
			}
			else {
				Vue.set(state.costs.tip, 'percentageValue', 0);
				Vue.set(state.costs.tip, 'amount', tipObject.tip);
			}
			Vue.set(state.costs.tip, 'type', tipObject.type);
		}
		else {
			Vue.set(state.costs.tip, 'percentageValue', 0);
			Vue.set(state.costs.tip, 'amount', 0);
		}
	},

	/**
	 * Update quantity from a unique OrderItem, we make sure to update the costs
	 * respectively as well.
	 *
	 * @param {CartState} state
	 * @param {any} quantityObject
	 * @return {void}
	 */
	UPDATE_QUANTITY(state: CartState, quantityObject: any): void {
		// Order date will only be present if ordering from a rotational menu, it's used to find the correct order to update
		if (quantityObject.orderDate) {
			const order = state.rotationalMenuOrders.find((order) => order.date === quantityObject.orderDate);

			if (order) {
				const index = order.items.findIndex(item => item.orderId === quantityObject.id);

				if(index > -1) {
					Vue.set(order.items[index], 'quantity', +quantityObject.quantity);
					Vue.set(order.items, index,  { ...setLocalOrderPrice(order.items[index]) });
				}
				updateRotationalMenuOrdersCookie({ orderId: order.orderId, date: quantityObject.orderDate, items: order.items }, restaurantState.restaurant.id);
			}
		}
		else {
			const index = state.items.findIndex(item => item.orderId === quantityObject.id);

			if(index > -1) {
				state.items[index].quantity = +quantityObject.quantity;
				state.items[index] = { ...setLocalOrderPrice(state.items[index]) };
				updateCartCookie(state.items, restaurantState.restaurant.id, suitesState.eventSuiteId);
			}
		}
	},

	/**
	 * Update the checkout pickup information
	 *
	 * @param {CartState} state
	 * @param {CheckoutPickupInfo} pickup
	 * @return {void}
	 */
	UPDATE_PICKUP_INFORMATION(state: CartState, pickup: CheckoutPickupInfo): void {
		Vue.set(state.checkout, 'pickup', pickup);
	},

	/**
	 * Update the checkout suites information
	 *
	 * @param {CartState} state
	 * @param {CheckoutSuitesInfo} suitesInfo
	 * @return {void}
	 */
	UPDATE_SUITES_INFORMATION(state: CartState, suitesInfo: CheckoutSuitesInfo): void {
		Vue.set(state.checkout, 'suitesInfo', suitesInfo);
	},

	/**
	 * Update the checkout delivery information
	 *
	 * @param {CartState} state
	 * @param {CheckoutDeliveryInfo} delivery
	 * @return {void}
	 */
	UPDATE_DELIVERY_INFORMATION(state: CartState, delivery: CheckoutDeliveryInfo): void {
		Vue.set(state.checkout, 'delivery', delivery);
	},

	/**
	 * Update the checkout contact information
	 *
	 * @param {CartState} state
	 * @param {CheckoutCardInfo} contact
	 * @return {void}
	 */
	UPDATE_CONTACT_INFORMATION(state: CartState, contact: CheckoutContactInfo): void {
		Vue.set(state.checkout, 'contact', contact);
	},

	/**
	 * Update the anonymous user's card info
	 *
	 * @param {CartState} state
	 * @param {CheckoutCardInfo} card
	 * @return {void}
	 */
	UPDATE_CARD_INFORMATION(state: CartState, card: CheckoutCardInfo): void {
		Vue.set(state.checkout, 'card', card);
	},

	/**
	 * Update the invoice value
	 *
	 * @param {CartState} state
	 * @param {boolean} invoice
	 * @return {void}
	 */
	UPDATE_INVOICE(state: CartState, invoice: boolean): void {
		Vue.set(state.checkout, 'invoice', invoice);
	},

	/**
	 * Update the cost center value
	 *
	 * @param {CartState} state
	 * @param {string} costCenter
	 * @return {void}
	 */
	UPDATE_COST_CENTER(state: CartState, costCenter: string): void {
		Vue.set(state.checkout, 'costCenter', costCenter);
	},

	/**
	 * Update the purchase order value
	 *
	 * @param {CartState} state
	 * @param {string} purchaseOrder
	 * @return {void}
	 */
	UPDATE_PURCHASE_ORDER(state: CartState, purchaseOrder: string): void {
		Vue.set(state.checkout, 'purchaseOrder', purchaseOrder);
	},

	/**
	 * Update the user's answers to the custom questions
	 *
	 * @param {CartState} state
	 * @param {CheckoutCustomQuestion[]} questions
	 * @return {void}
	 */
	UPDATE_CUSTOM_QUESTIONS(state: CartState, questions: CheckoutCustomQuestion[]): void {
		Vue.set(state.checkout, 'questions', questions);
	},

	/**
	 * Update the option to save a payment method for Web Login users
	 *
	 * @param {CartState} state
	 * @param {savePayment} boolean
	 * @return {void}
	 */
	SET_IS_USER_SAVING_PAYMENT_METHOD_ON_CHECKOUT(state: CartState, savePaymentMethodOnCheckout: boolean): void {
		Vue.set(state.checkout, 'savePaymentMethodOnCheckout', savePaymentMethodOnCheckout);
	},

	/**
	 * Update the if the membership banner should be shown
	 *
	 * @param {CartState} state
	 * @param {showBanner} boolean
	 * @return {void}
	 */
	SHOW_MEMBERSHIP_BANNER(state: CartState, showBanner: boolean): void {
		Vue.set(state, 'showMembershipBanner', showBanner);
	},

	/**
	 * Update payment method
	 *
	 * @param {CartState} state
	 * @param {string} paymentMethod
	 * @return {void}
	 */
	UPDATE_PAYMENT_METHOD(state: CartState, paymentMethod: string): void {
		// If the credit card option was chosen and the user is logged in, select a default
		// card depending on his account info
		if(paymentMethod === 'credit_card' && authState.user.id) {
			if(authState.user.paymentMethods && authState.user.paymentMethods.length) {
				authState.user.paymentMethods.forEach(creditCard => {
					if(creditCard.isDefault) {
						state.chosenCreditCard = creditCard;
					}
				});
				// If somehow there is no default card, select the first one in the array
				if(!state.chosenCreditCard!.paymentCardId.length) {
					state.chosenCreditCard = authState.user.paymentMethods[1];
				}
			}
			else {
				state.chosenCreditCard = null;
			}
		}
		// Make sure to clear out any CC details if the payment method is
		// switched from CC to another one.
		else {
			state.checkout.card = {
				number: '',
				cvd: '',
				expiry_date: '',
				postal_code: '',
				type: ''
			};
			state.chosenCreditCard = null;
			state.checkout.savePaymentMethodOnCheckout = false;
		}

		// Extra payment methods
		state.checkout.invoice = (paymentMethod === 'invoice');

		if(paymentMethod !== 'costCenter') {
			state.checkout.costCenter = null;
		}

		if(paymentMethod !== 'purchaseOrder') {
			state.checkout.purchaseOrder = null;
		}

		Vue.set(state.checkout, 'paymentMethod', paymentMethod);
	},

	/**
	 * Update the anonymous user's chosen credit card
	 *
	 * @param {CartState} state
	 * @param {SavedPaymentOption} chosenCreditCard
	 * @return {void}
	 */
	UPDATE_CHOSEN_CREDIT_CARD(state: CartState, chosenCreditCard: SavedPaymentOption | null): void {
		Vue.set(state, 'chosenCreditCard', chosenCreditCard);
	},

	/**
	 * Update the user's App8 saved payment credit cards
	 * If there is no chosenCreditCard we cycle through and
	 * check for the default card on the user profile, if for
	 * some reason the user has cards but no default one, we
	 * put the chosenCreditCard to the first row
	 *
	 *
	 * @param {CartState} state
	 * @param {SavedPaymentOption[]} methods
	 * @return {void}
	 */
	UPDATE_PAYMENT_OPTION(state: CartState, methods: SavedPaymentOption[]): void {
		if(methods) {
			if(methods.length > 1) {
				methods.sort((a: SavedPaymentOption, b: SavedPaymentOption) => Number(b.isDefault) - Number(a.isDefault));
				methods.forEach(method => {
					if(method.isDefault) {
						state.chosenCreditCard = method;
					}
				});
			}
			else if (methods.length === 1) {
				state.chosenCreditCard = methods[0];
			}
		}
	},

	/**
	 * Save query params to the vuex store
	 *
	 * @param {CartState} state
	 * @param {any} queryParams
	 * @return {void}
	 */
	PROCESS_QUERY_PARAMS(state: CartState, queryParams: any): void {
		// Menu config
		state.config.tableNum = queryParams['tableNum'];
		state.config.takeout = queryParams['takeout'];
		state.config.takeoutType = queryParams['takeoutType'];
		state.config.genericCatering = queryParams['catering'];
		state.config.viewOnly = queryParams['viewOnly'];
		state.config.menuList = queryParams['menuList'];
		state.config.genericTableLocation = queryParams['genericTableLocation'];
		suitesState.eventSuiteId = +queryParams['eventSuite'];
		suitesState.eventDayOrdering = !!queryParams['eventDayOrdering'];
		if((state.config.takeout || state.config.genericCatering || restaurantState.restaurant.k12) && restaurantState.restaurant.delivery && restaurantState.restaurant.delivery.type && state.config.takeoutType !== 'pickup') {
			Vue.set(state.checkout, 'pickup', { delivery: true });
		}

		// Temporary date query params
		state.config.takeoutDateRange = queryParams['dateRange'];
		state.config.takeoutSpecificDates = queryParams['dates'];

		// Rotational menu date query params
		const startDate = DateTime.fromISO(queryParams['selectedWeek']);
		restaurantState.rotationalMenuDateRange = {
			start: startDate.startOf('week').toISODate(),
			end: startDate.endOf('week').toISODate()
		}
	},

	/**
	 * Set the selected table (number and area)
	 *
	 * @param {CartState} state
	 * @param {GenericTableLocation} payload
	 * @return {void}
	 */
	 SET_SELECTED_TABLE(state: CartState, payload: GenericTableLocation): void {
		Vue.set(state.config, 'tableNum', payload.tableNum);
		if(payload.location) {
			Vue.set(state.config, 'tableInfo', { tableNum: payload.tableNum, tableLocation: payload.location, tableArea: payload.area })
		}
	},

	/**
	 * Update the modal error message
	 *
	 * @param {CartState} state
	 * @param {ErrorValidation} errorValidation
	 * @return {void}
	 */
	UPDATE_VALIDATION_ERROR(state: CartState, errorValidation: ErrorValidation): void {
		Vue.set(state, 'errorValidation', errorValidation);
	},

	/**
	 * Update the delivery status *NEEDS REFACTOR*
	 *
	 * @param {CartState} state
	 * @param {any} value
	 * @return {void}
	 */
	UPDATE_DELIVERY_STATUS(state: CartState, value: any): void {
		Vue.set(state.checkout, 'pickup', value);
	},

	/**
	 * Update the today date value to prevent adding required notice, etc to it if
	 * the user go back to the takeoutinformation component
	 *
	 * @param {CartState} state
	 * @param {any} value
	 * @return {void}
	 */
	SET_TODAY_DATE(state: CartState, value: any): void {
		Vue.set(state, 'today', value);
	},

	/**
	 * User selected a date from the datepicker, set the boolean to true.
	 * This flag is used for the date range and dates at the moment, to not set
	 * the date again when the user go back to the component.
	 *
	 * @param {CartState} state
	 * @param {any} value
	 * @return {void}
	 */
	 SET_DATE_SELECTED_BY_USER_FLAG(state: CartState, value: any): void {
		Vue.set(state, 'dateSelectedByUser', value);
	},

	/**
	 * Set the tip selected to the boolean value
	 *
	 * @param {CartState} state
	 * @param {boolean} value
	 * @return {void}
	 */
	SET_TIP_SELECTED(state: CartState, value: boolean): void {
		Vue.set(state, 'tipSelected', value);
	},

	/**
	 * Set the google pay payment payload
	 *
	 * @param {CartState} state
	 * @param {CheckoutGooglePayPayload | null} googlePayPaymentPayload
	 * @return {void}
	 */
	SET_GOOGLE_PAY_PAYMENT_PAYLOAD(state: CartState, googlePayPaymentPayload: CheckoutGooglePayPayload | null): void {
		Vue.set(state.checkout, 'googlePayPaymentPayload', googlePayPaymentPayload);
	},

	/**
	 * Set the apple pay payment token
	 *
	 * @param {CartState} state
	 * @param {string | null} applePayPaymentToken
	 * @return {void}
	 */
	SET_APPLE_PAY_PAYMENT_PAYLOAD(state: CartState, applePayPaymentPayload: ApplePayPaymentPayload): void {
		Vue.set(state.checkout, 'applePayPaymentToken', applePayPaymentPayload.applePayPaymentToken);
		Vue.set(state.checkout, 'applePayPaymentDetails', applePayPaymentPayload.applePayDetails);
	},

	/**
	 * Set the legal check to true
	 *
	 * @param {CartState} state
	 * @param {boolean} value
	 * @return {void}
	 */
	SET_LEGAL(state: CartState, value: boolean): void {
		Vue.set(state, 'legal', value);
	},

	/**
	 * Set the discount applied
	 *
	 * @param {CartState} state
	 * @param {any} discount
	 * @return {void}
	 */
	SET_DISCOUNT(state: CartState, discount: any): void {
		// Set the total to 0 first before the calculations for reset
		Vue.set(state.costs.discount, 'amount', 0);
		if(discount && discount.name) {
			Vue.set(state.costs.discount, 'code', discount.name);
			Vue.set(state.costs.discount, 'type', discount.value.slice(discount.value.length - 1) === '$' ? 'dollar' : 'percentage');

			// Set the percentage value for display purposes
			if(state.costs.discount.type === 'percentage') {
				Vue.set(state.costs.discount, 'percentageValue', +discount.value.slice(0, -1));
			}
		}
		else {
			Vue.set(state.costs.discount, 'code', '');
			Vue.set(state.costs.discount, 'percentageValue', 0);
			Vue.set(state.costs.discount, 'type', 'percentage');
		}
	},

	/**
	 * Set the voucher applied
	 *
	 * @param {CartState} state
	 * @param {any} voucher
	 * @return {void}
	 */
	SET_VOUCHER(state: CartState, voucher: any): void {
		if(voucher && voucher.code) {
			Vue.set(state.costs.voucher, 'code', voucher.code);
			Vue.set(state.costs.voucher, 'balance', voucher.balance);
		}
		else {
			Vue.set(state.costs.voucher, 'code', '');
			Vue.set(state.costs.voucher, 'balance', 0);
		}
		Vue.set(state.costs.voucher, 'amount', 0);
	},

	/**
	 * Calcuate the cart item member prices if there are any when the
	 * user signs in as a member
	 *
	 * @param {CartState} state
	 * @param {any} voucher
	 * @return {void}
	 */
	CALCULATE_CART_ITEMS_MEMBER_PRICES(state: CartState): void {
		if(state.items && state.items.length) {
			state.items.forEach((item: OrderItem, index: number) => {
				state.items[index] = { ...setLocalOrderPrice(item) };
			});
		}
	},

	/**
	 * Remove all rotational menu orders that have passed the cutoff date
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	REMOVE_ORDERS_PASSED_CUTOFF(state: CartState): void {
		const cutoffDate = restaurantState.restaurant.orderCutoffDate;
		if (cutoffDate) {
			state.rotationalMenuOrders = state.rotationalMenuOrders.filter(order => {
				const orderDate = DateTime.fromISO(order.date).startOf('day');
				return orderDate >= cutoffDate;
			});
		}
	},

	/**
	 * Clear the store's items and card info
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	CLEAR_ITEMS_AND_CARD_INFO(state: CartState): void {
		state.items = [];
		state.rotationalMenuOrders = [];
		state.checkout.card = {
			number: '',
			cvd: '',
			expiry_date: '',
			postal_code: '',
			type: ''
		};
		state.costs.voucher = {
			amount: 0,
			balance: 0,
			code: ''
		};
		state.checkout.googlePayPaymentPayload = null;
		state.checkout.applePayPaymentToken = null;
		state.checkout.invoice = false;
		state.checkout.costCenter = null;
		state.checkout.purchaseOrder = null;
		state.checkout.loyaltyProgram = null;
		state.checkout.savePaymentMethodOnCheckout = false;
		Vue.set(state, 'temporarySelectedItem', {});
		Cookies.remove(CART_COOKIE);
		Cookies.remove(ROTATIONAL_MENU_ORDERS_COOKIE);
	},

	/**
	 * Reset the store's checkout info and items to their initial state.
	 * If the user information is set already, we fill it up.
	 * We do not reset member info.
	 *
	 * @param {CartState} state
	 * @return {void}
	 */
	RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS(state: CartState): void {
		// Only reset the items if ordering requires login and the user is not authenticated
		if (store.getters['restaurant/orderingRequiresLogin'] && !authState.authenticated) {
			state.items = [];
			state.rotationalMenuOrders = [];
			Cookies.remove(CART_COOKIE);
			Cookies.remove(ROTATIONAL_MENU_ORDERS_COOKIE);
		}
		state.chosenCreditCard = null;
		Vue.set(state, 'checkout', {
			paymentMethod: '',
			pickup: null,
			delivery: null,
			contact: authState.user && authState.user.id ?
				{
					full_name: authState.user.firstName + ' ' + authState.user.lastName,
					phone_number: authState.user.phoneNumber,
					email: authState.user.email
				} :
				null,
			card: null,
			savePaymentMethodOnCheckout: false,
			applePayPaymentToken: null,
			googlePayPaymentPayload: null,
			invoice: false,
			costCenter: null,
			purchaseOrder: null,
			memberInfo: state.checkout && state.checkout.memberInfo ? state.checkout.memberInfo : null,
			loyaltyProgram: null,
			suitesInfo: null
		});
		if ((state.config.takeout || state.config.genericCatering || restaurantState.restaurant.k12) && restaurantState.restaurant.delivery?.type && state.config.takeoutType !== 'pickup') {
			Vue.set(state.checkout, 'pickup', { delivery: true });
		}
	}
};