import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RestaurantState, RootState } from '../types';
const namespaced: boolean = true;

export const state: RestaurantState = {
	restaurant: {} as Restaurant,
	initialMenuGroups: [],
	initialMenus: [],
	itemList: [],
	memberMenus: [],
	filteredMenus: [],
	paymentConfig: null,
	rotationalMenus: [],
	rotationalMenuDateRange: {
		start: null,
		end: null
	},
	rotationalMenusFetching: false
};

export const Menus: Module<RestaurantState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Menus;