import Vue from 'vue';
import router from '@/router';
import { MutationTree } from 'vuex';
import { DateTime } from 'luxon';
import { RestaurantState, CartState } from '../types';
import { formatAndSortMenus } from '@/utils/http';

/**
 * Compare item's promo
 *
 * @param {MenuItem | OrderItem} item
 * @param {CheckoutMemberInfo} memberInfo
 * @param {string} arrayName // options by default, orderOptions can be passed for order items
 * @param {boolean} hasPricingOptions
 * @return {void}
 */
function compareItem(item: any, memberInfo: CheckoutMemberInfo, arrayName: string = 'options', hasPricingOptions: boolean = false): void {
	// If the item is an alcoholic item, we do not apply any discount
	if(!item.alcoholic_beverage) {

		// If we are treating cart items, we do not want to treat promos if the item has a pricing option
		if(!hasPricingOptions) {
			// If the item has promos we want to apply them if the user has them as well
			if(item.promos) {
				let tempPromoInfo: TemporaryPromoInfo = {
					promoFound: false,
					itemMemberPrice: Number(item.price)
				};

				// Loop through the item/member promos
				item.promos.forEach((promo: string) => {
					tempPromoInfo = compareMemberPromosAndDiscountPrice(item, promo, memberInfo, tempPromoInfo.itemMemberPrice, tempPromoInfo.promoFound);
				});

				// Assign the member price to the item
				item.memberPrice = tempPromoInfo.promoFound ? tempPromoInfo.itemMemberPrice.toFixed(2) : undefined;
			}
		}

		// Options
		compareOptions(item, memberInfo, arrayName, hasPricingOptions);
	}
}

/**
 * Compare options and sub options promos
 *
 * @param {MenuItem | OrderItem | Option} item
 * @param {CheckoutMemberInfo} memberInfo
 * @param {string} arrayName // options by default, orderOptions can be passed for order items
 * @param {boolean} hasPricingOptions
 * @return {void}
 */
function compareOptions(item: any, memberInfo: CheckoutMemberInfo, arrayName: string = 'options', hasPricingOptions: boolean = false): void {
	if(item[arrayName]) {
		item[arrayName].forEach((optionGroup: OptionGroup | OrderOptionGroup) => {
			if(optionGroup.values) {
				optionGroup.values.forEach((option: Option | OrderOption) => {
					if(option.promos) {
						let tempPromoInfo: TemporaryPromoInfo = {
							promoFound: false,
							itemMemberPrice: Number(option.price)
						};

						// Loop through the option/member promos
						option.promos.forEach((promo: string) => {
							tempPromoInfo = compareMemberPromosAndDiscountPrice((option as Option), promo, memberInfo, tempPromoInfo.itemMemberPrice, tempPromoInfo.promoFound);
						});

						// Assign the member price to the item
						option.memberPrice = tempPromoInfo.promoFound ? tempPromoInfo.itemMemberPrice.toFixed(2) : undefined;

						// If we are treating cart items and the order option is of pricing type, we have to also modify
						// the item's price with the pricing member price.
						if(hasPricingOptions && (optionGroup as OrderOptionGroup).optionGroupType === 'pricing') {
							item.memberPrice = option.memberPrice;
						}
					}

					// Recursivity - compare sub options
					if(option.options) {
						compareOptions(option, memberInfo);
					}
				});
			}
		});
	}
}

/**
 * Loop through the member's promo array and compare them with the item's promo.
 * If they are the same then we discount the price with a percentage or a dollar
 * amount. We then return the price to add to the temp price.
 * Reminder: for a promo to work, the id has to be the same and we then use the object information
 * from the member's promo array to get the percent/amount
 *
 * @param {MenuItem | OrderItem | Option} item
 * @param {CheckoutMemberInfo} memberInfo
 * @param {number} tempMemberPrice
 * @param {boolean} promoFound
 * @return {any}
 */
function compareMemberPromosAndDiscountPrice(item: MenuItem | OrderItem | Option, itemPromo: string, memberInfo: CheckoutMemberInfo, itemMemberPrice: number, promoFound: boolean): any {
	// Loop through the member promos to check if they compare to the item's one
	memberInfo.promos.forEach((memberPromo: MemberPromo) => {

		// If the item contains the promo the member has we create a memberPrice for that item
		if(itemPromo === memberPromo.id) {
			memberInfo.promosApplied.add(memberPromo.id);
			promoFound = true;

			// Percentage
			if(memberPromo.percent) {
				itemMemberPrice = Math.max(0, Number(itemMemberPrice) - (Number(itemMemberPrice) * (memberPromo.percent / 100)));
			}

			// Dollar
			else {
				itemMemberPrice = Math.max(0, Number(itemMemberPrice) - memberPromo.amount);
			}
		}
	});
	return { promoFound, itemMemberPrice };
}

export const mutations: MutationTree<RestaurantState> = {

	/**
	 * Set restaurant
	 *
	 * @param {RestaurantState} state
	 * @param {Restaurant} restaurant
	 * @return {void}
	 */
	 SET_RESTAURANT(state: RestaurantState, restaurant: Restaurant): void {
		 Vue.set(state, 'restaurant', restaurant);
	},

	/**
	 * Set initial menu groups array
	 *
	 * @param {RestaurantState} state
	 * @param {MenuGroup[]} initialMenuGroups
	 * @return {void}
	 */
	 SET_INITIAL_MENU_GROUPS(state: RestaurantState, initialMenuGroups: MenuGroup[]): void {
		 Vue.set(state, 'initialMenuGroups', initialMenuGroups);
	},

	/**
	 * Set initial menus array and set the item list of all items
	 *
	 * @param {RestaurantState} state
	 * @param {Menu[]} initialMenus
	 * @return {void}
	 */
	 SET_INITIAL_MENUS(state: RestaurantState, initialMenus: Menu[]): void {
		 Vue.set(state, 'initialMenus', initialMenus);
		 initialMenus.forEach((menu: Menu) => {
			 if(menu.sections) {
				 menu.sections.forEach((section: MenuSection) => {
					 section.items.forEach((item: MenuItem) => {
						state.itemList.push(item);
					 });
				 });
			}
			else {
				menu.items.forEach((item: MenuItem) => {
					state.itemList.push(item);
				});
			}
		});
	},

	/**
	 * Set filtered menus array
	 *
	 * @param {RestaurantState} state
	 * @param {Menu[]} filteredMenus
	 * @return {void}
	 */
	 SET_FILTERED_MENUS(state: RestaurantState, filteredMenus: Menu[]): void {
		 Vue.set(state, 'filteredMenus', filteredMenus);
	},

	SET_ROTATIONAL_MENUS(state: RestaurantState, rotationalMenus: RotationalMenu[]): void {
		// Update the date range if the week of the current date is before the week of the first rotational menu date
		// This will happen if the ordering cutoff pushes the next valid ordering date to the next week
		// Ex: If it's Thursday and the cutoff is 5 days, the next valid date will be next the Tuesday of next week
		if (rotationalMenus.length) {
			const selectedWeek = state.rotationalMenuDateRange?.start ? DateTime.fromISO(state.rotationalMenuDateRange.start) : null;
			const rotationalMenuDate = DateTime.fromISO(rotationalMenus[0].date)

			if (selectedWeek && rotationalMenuDate.startOf('week') > selectedWeek.startOf('week')) {
				const updatedCurrentDate = rotationalMenuDate.startOf('week').toISODate();

				Vue.set(state, 'rotationalMenuDateRange', {
					start: updatedCurrentDate,
					end: rotationalMenuDate.endOf('week').toISODate()
				});

				router.replace({ query: { ...router.currentRoute.query, selectedWeek: updatedCurrentDate } });
			}

		}

		const formattedRotationalMenus = rotationalMenus.map((rotationalMenu: RotationalMenu) => {
			return {
				...rotationalMenu,
				menus: formatAndSortMenus(rotationalMenu.menus, state.restaurant)
			}
		});
		Vue.set(state, 'rotationalMenus', formattedRotationalMenus);
	},

	SET_ROTATIONAL_MENU_DATE_RANGE(state: RestaurantState, dateRange: DateRange): void {
		Vue.set(state, 'rotationalMenuDateRange', dateRange);
	},

	SET_ROTATIONAL_MENUS_FETCHING(state: RestaurantState, fetching: boolean): void {
		Vue.set(state, 'rotationalMenusFetching', fetching);
	},

	/**
	 * Set the member prices on items/options that contains the same promo
	 * of the member's payload.
	 *
	 * @param {RestaurantState} state
	 * @param {Menu[]} menus
	 * @return {void}
	 */
	SET_MEMBER_PRICES(state: RestaurantState, menus: Menu[]): void {
		const cartState: CartState = (this.state as any).cart;
		if(cartState.checkout.memberInfo && cartState.checkout.memberInfo.promos && cartState.checkout.memberInfo.promos.length) {

			// Set cart items' member price
			if(cartState.items && cartState.items.length) {
				cartState.items.forEach((item: OrderItem) => {

					// We need to know if the cart item has pricing options because if it does we don't want to manipulate the price
					// directly from the item
					const hasPricingOptions = item.orderOptions && item.orderOptions.some((orderOption: OrderOptionGroup) => orderOption.optionGroupType === 'pricing') || false;
					compareItem(item, cartState.checkout.memberInfo!, 'orderOptions', hasPricingOptions);
				});
			}

			// Set menu items' member price
			menus.forEach((menu: Menu) => {

				// Section items
				if(menu.sections) {
					menu.sections.forEach((section: MenuSection) => {
						section.items.forEach((item: MenuItem) => {
							compareItem(item, cartState.checkout.memberInfo!);
						});
					});
				}

				// Menu items
				else {
					menu.items.forEach((item: MenuItem) => {
						compareItem(item, cartState.checkout.memberInfo!);
					});
				}
			});
		}
		Vue.set(state, 'memberMenus', menus);
	},

	/**
	 * Set payment config payload
	 *
	 * @param {RestaurantState} state
	 * @param {PaymentConfig} payload
	 * @return {void}
	 */
	SET_PAYMENT_CONFIG(state: RestaurantState, payload: PaymentConfig): void {
		Vue.set(state, 'paymentConfig', payload);
	},

	RESET_RESTAURANT_STATE(state: RestaurantState): void {
		state.restaurant = {} as Restaurant;
		state.initialMenuGroups = [];
		state.initialMenus = [];
		state.itemList = [];
		state.memberMenus = [];
		state.filteredMenus = [];
		state.paymentConfig = null;
	}
};
